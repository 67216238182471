var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"d-flex justify-center align-center ma-2"},[_c('v-icon',{attrs:{"large":"","left":"","color":"orange"}},[_vm._v("mdi-package-variant-closed")]),_c('div',{staticClass:"text-h5 pa-4 orange--text darken-2"},[_vm._v("นัดหมายรับเวชภัณฑ์")]),_c('v-spacer')],1),_c('v-divider'),_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"title",attrs:{"active-class":"orange darken-2 white--text rounded-top"}},[_vm._v(" รายการที่กำลังดำเนินการ ")]),_c('v-tab',{staticClass:"title",attrs:{"active-class":"blue darken-2 white--text rounded-top"}},[_vm._v(" รายการที่เสร็จสิ้นแล้ว ")]),_c('v-tabs-slider',{attrs:{"color":"white"}})],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"color":"orange"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"items-per-page":5,"items":_vm.asnTable.items.ongoing,"headers":_vm.asnTable.header,"footer-props":{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){return [_c('v-btn',{attrs:{"icon":"","outlined":""}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)]}},{key:"item.detail",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":function($event){return _vm.applyRouterLink(_vm.$routes.ASN_DETAIL.name, item)}}},[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}])})],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"pa-4"},[_c('v-data-table',{attrs:{"items-per-page":5,"items":_vm.asnTable.items.completed,"headers":_vm.asnTable.header,"footer-props":{
            'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
            pageText: 'แสดง {0}-{1} จาก {2}',
          }},scopedSlots:_vm._u([{key:"item.document",fn:function(ref){return [_c('v-btn',{attrs:{"icon":"","outlined":""}},[_c('v-icon',[_vm._v("mdi-file-document-outline")])],1)]}},{key:"item.detail",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","outlined":""},on:{"click":function($event){return _vm.applyRouterLink(_vm.$routes.ASN_DETAIL.name, item)}}},[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }