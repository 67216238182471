<template>
  <v-container>
    <!-- Header -->
    <v-row class="d-flex justify-center align-center ma-2">
      <v-icon large left color="orange">mdi-package-variant-closed</v-icon>
      <div class="text-h5 pa-4 orange--text darken-2">นัดหมายรับเวชภัณฑ์</div>
      <v-spacer />
      <!--
            <v-btn tile large color="blue darken-2 white--text" @click="applyRouterLink($routes.IMPORT.name)">
                <v-icon class="pr-2">mdi-package-variant-closed</v-icon>
                นำเข้าเวชภัณฑ์เพิ่มเติม
            </v-btn>
            -->
    </v-row>
    <v-divider />
    <!-- TAB HEADER -->
    <v-tabs v-model="tab" grow>
      <v-tab class="title" active-class="orange darken-2 white--text rounded-top">
        รายการที่กำลังดำเนินการ
      </v-tab>
      <v-tab class="title" active-class="blue darken-2 white--text rounded-top">
        รายการที่เสร็จสิ้นแล้ว
      </v-tab>
      <v-tabs-slider color="white"></v-tabs-slider>
    </v-tabs>
    <!-- <v-divider class="gray darken-2" /> -->
    <v-tabs-items v-model="tab">
      <!-- Ongoing List -->
      <v-tab-item color="orange">
        <v-card class="pa-4">
          <v-data-table
            :items-per-page="5"
            :items="asnTable.items.ongoing"
            :headers="asnTable.header"
            :footer-props="{
              'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
              pageText: 'แสดง {0}-{1} จาก {2}',
            }"
          >
            <template v-slot:item.document="{}">
              <v-btn icon outlined>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.detail="{ item }">
              <v-btn
                icon
                outlined
                @click="applyRouterLink($routes.ASN_DETAIL.name, item)"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>

      <!-- Completed List -->
      <v-tab-item>
        <v-card class="pa-4">
          <v-data-table
            :items-per-page="5"
            :items="asnTable.items.completed"
            :headers="asnTable.header"
            :footer-props="{
              'items-per-page-text': 'จำนวนรายการที่แสดงต่อหน้า',
              pageText: 'แสดง {0}-{1} จาก {2}',
            }"
          >
            <template v-slot:item.document="{}">
              <v-btn icon outlined>
                <v-icon>mdi-file-document-outline</v-icon>
              </v-btn>
            </template>
            <template v-slot:item.detail="{ item }">
              <v-btn
                icon
                outlined
                @click="applyRouterLink($routes.ASN_DETAIL.name, item)"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
import axios from "axios";
// const url = "http://localhost:8910/api";
// const url = "http://anantasolutions.co/flagship/api"
const url = "http://164.115.22.214/api"
export default {
  data: () => {
    return {
      tab: null,
      asnTable: {
        header: [
          {
            text: "วันที่ทำรายการ",
            value: "date",
          },
          {
            text: "เลขที่เอกสาร",
            value: "id",
          },
          {
            text: "SPD",
            value: "spd",
          },
          {
            text: "กำหนดส่งโดยประมาณ",
            value: "eta",
          },
          {
            text: "สถานะรายการ",
            value: "status",
          },
          {
            text: "เอกสารที่เกี่ยวข้อง",
            value: "document",
          },
          {
            text: "รายละเอียดรายการ",
            value: "detail",
          },
        ],
        items: {
          ongoing: [],
          completed: [],
        },
      },
      ongoingAsnItemList: [],
    };
  },
  methods: {
    applyRouterLink(name, asn) {
      this.$router.push({ name, params: { asn: asn } });
    },
    initialize() {
      // this.asnTable.items.ongoing = this.ongoingAsnItemList
      // this.asnTable.items.completed = this.ongoingAsnItemList
      this.fetchASN();
    },
    fetchASN() {
      // console.log('fetchASN')
      axios
        .post(url + "/pcoc/get_asn", {
          hosp_code: this.$cookies.get("user").hosp_code,
        })
        .then((response) => {
          // console.log(response.data);
          this.asnTable.items.ongoing = new Array();
          this.asnTable.items.completed = new Array();
          response.data.asns.forEach((asn) => {
            
            var item = {
              date: new Date(asn.asn_date).toLocaleString("th-TH"),
              id: asn.asn_number,
              spd: "ไปรษณีย์ดิสทริบิวชั่น",
              eta: new Date(asn.shipped_date).toLocaleString("th-TH"),
              status: asn.status,
              list: asn.items,
            };
            if (asn.status == 1001 || asn.status == 1002) this.asnTable.items.completed.push(item);
            else if(asn.status == 1000) this.asnTable.items.ongoing.push(item);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
  mounted() {
    this.initialize();
    // this.fetchASN()
  },
};
</script>